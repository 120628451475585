<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Private venue requests</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Private venue requests
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="9">
                  <v-text-field
                      label="Lookup by name, code or email"
                      clearable
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchVenueRequests"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchVenueRequests"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Name</th>
                  <th class="px-3">Location</th>
                  <th class="px-3">Code</th>
                  <th class="px-3">Requested date</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="venueRequests.length > 0" v-for="item in venueRequests">
                    <td class="px-3">
                      <a @click="viewVenueRequest(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.name ? item.name : '-' }}
                      </a>
                      <br>
                      <span v-if="item.email">
                        <i class="fa fa-envelope"></i> {{item.email}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span  class="font-weight-bolder">
                        {{ item.location_name ? item.location_name : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                          {{ item.unique_code ? item.unique_code : '-' }}
                        </span>
                    </td>
                    <td>
                      {{ item.requested_date ? item.requested_date : '-' }}
                    </td>
                    <td>
                      <span v-html="item.status_badge"></span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text @click="viewVenueRequest(item)" tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                                <span class="navi-text">Review request</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="venueRequests.length == 0">
                    <td colspan="5" class="text-center">
                      <strong>No data found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="venueRequests.length > 0"
                  class="pull-right mt-7"
                  @input="getAllVenueRequests"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="isLoading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <quick-view ref="view-venue" @refresh="getAllVenueRequests" ></quick-view>
    </div>
  </v-app>
</template>
<script>
import VenueService from "@/services/practical-exam/venue/VenueService";
import QuickView from "./QuickView";

const venue=new VenueService();

export default {
  components:{
    QuickView
  },
  data(){
    return{
      isLoading:false,
      search:{
        info: ''
      },
      venueRequests:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      currentUser:{}
    }
  },
  methods:{
    getAllVenueRequests(){
      this.isLoading=true;
      venue
          .getVenueRequests(this.search,this.page)
          .then(response => {
            this.venueRequests=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading=false;
          });
    },
    viewVenueRequest(venue){
      this.$refs['view-venue'].showVenue(venue);
    },
    searchVenueRequests(){
      this.getAllVenueRequests();
    },
  },
  mounted() {
    this.getAllVenueRequests();
  }
}
</script>