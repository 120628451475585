<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-row v-if="!venue">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-card v-else>
      <v-toolbar flat>
        <v-card-title>
          <span> Review private venue request</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <table class="table">
              <tbody>
              <tr>
                <th>Venue</th>
                <td  class="pl-8">  {{venue.name ? venue.name : '-'}}</td>
              </tr>
              <tr>
                <th>Venue code</th>
                <td  class="pl-8"> {{venue.unique_code ? venue.unique_code : '-'}}</td>
              </tr>
              <tr>
                <th>Venue owner</th>
                <td  class="pl-8">  {{venue.user_name ? venue.user_name : '-'}}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td  class="pl-8"> {{venue.location_name ? venue.location_name : '-'}}</td>
              </tr>

              <tr>
                <th>Address line 1</th>
                <td  class="pl-8">  {{venue.address_line_1 ? venue.address_line_1 : '-'}}</td>
              </tr>
              <tr>
                <th>Address line 2</th>
                <td  class="pl-8">  {{venue.address_line_2 ? venue.address_line_2 : '-'}}</td>
              </tr>
              <tr>
                <th>Suburb/town</th>
                <td  class="pl-8"> {{venue.suburb ? venue.suburb : '-'}}</td>
              </tr>
              <tr>
                <th>Post code</th>
                <td  class="pl-8"> {{venue.post_code ? venue.post_code : '-'}}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td  class="pl-8">{{venue.email ? venue.email : '-'}}</td>
              </tr>
              <tr>
                <th>Owner remark</th>
                <td  class="pl-8"> {{venue.owner_remark ? venue.owner_remark : 'N/A'}}</td>
              </tr>
              <tr>
                <th>Venue instructions</th>
                <td  class="pl-8"> {{venue.instructions ? venue.instructions : 'N/A'}}</td>
              </tr>
              <tr v-if="venue.map_image">
                <th>Map image</th>
                <td  class="pl-8"> <a :href="venue.map_image.original" target="_blank">
                  <i class="fa fa-link"></i>
                </a></td>
              </tr>
              <tr  v-if="venue.image_one">
                <th>Image one</th>
                <td  class="pl-8"> <a :href="venue.image_one.original" target="_blank">
                  <i class="fa fa-link"></i>
                </a></td>
              </tr>
              <tr v-if="venue.image_two">
                <th >Image two</th>
                <td  class="pl-8"> <a :href="venue.image_two.original" target="_blank">
                  <i class="fa fa-link"></i>
                </a></td>
              </tr>
              <tr v-if="venue.image_three">
                <th >Image three</th>
                <td  class="pl-8"> <a :href="venue.image_three.original" target="_blank">
                  <i class="fa fa-link"></i>
                </a></td>
              </tr>
              <tr v-if="venue.other_attachment">
                <th>Other attachment</th>
                <td class="pl-8"> <a
                    class=""
                    target="_blank"
                    :href="venue.other_attachment.thumb"
                >
                  <i class="fa fa-link"></i>
                </a></td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="venue.approval_status == 'pending'"
            text
            x-large
            :loading="rejectLoading"
            @click="rejectStatus"
        >
            Not approve
        </v-btn>
        <v-btn
            v-if="venue.approval_status == 'pending'"
            dark
            x-large
            :loading="approveLoading"
            medium
            @click="approveStatus"
        >
          Approve
        </v-btn>
<!--        <v-btn-->
<!--            color="btn btn-standard"-->
<!--            dark-->
<!--            medium-->
<!--            @click="closeDialog">-->
<!--          Close-->
<!--        </v-btn>-->

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VenueService from "@/services/practical-exam/venue/VenueService";
const venue=new VenueService();

export default{
    data(){
        return{
            loading: true,
            venue:'',
            dialog: false,
            approveLoading: false,
            rejectLoading: false,
        }
    },
    methods:{
        closeDialog(){
            this.dialog = false;
        },
        showVenue(venue){
            this.venue = venue;
            this.openDialog();
        },
        openDialog(){
            this.dialog = true;
        },
        approveStatus(){
            this.$confirm({
                message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
                },
                callback: (confirm) => {
                if (confirm) {
                    this.approveLoading = true;
                    venue
                    .approveRequest(this.venue.id)
                    .then((response) => {
                        this.$snotify.success('Venue request has been approved.');
                        this.approveLoading = false;
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch((err) => {
                        this.$snotify.error('Oops, the venue request could not be approved.');
                        this.approveLoading = false;
                        this.closeDialog();
                    })
                    .finally(() => {
                    });
                }
                }
            })
        },
        rejectStatus(){
            this.$confirm({
                message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
                },
                callback: (confirm) => {
                if (confirm) {
                    this.rejectLoading = true;
                    venue
                    .rejectRequest(this.venue.id)
                    .then((response) => {
                        this.$snotify.success('Venue request has been rejected.');
                        this.rejectLoading = false;
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch((err) => {
                        this.$snotify.error('Oops, the venue request could not be rejected.');
                        this.rejectLoading = false;
                        this.closeDialog();
                    })
                    .finally(() => {
                    });
                }
                }
            })
        },
    }
}
</script>

